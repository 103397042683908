.tableContainer {
  margin-top: 44px;
}

.tableCell {
  text-align: center;
}

.tableHover {
  cursor: pointer;
}

.tableHover:hover {
  background-color: #dbdbdb;
}

.goBack {
  cursor: pointer;
  background-color: #f8f8f8 !important;
  color: black !important;
  font-size: 18px !important;
  height: 32px !important;
}
.goBack:hover {
  background-color: #dbdbdb !important;
  box-shadow: none !important;
}
