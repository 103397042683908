.chartContainer {
  width: 240px;
  height: 250px;
}

.chartWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.progressBarWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reimbursementGoalTrackerRoot {
  height: calc(100% - 80px);
  width: calc(100% - 60px);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
