.progressBarContainer {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.progressBarLabel {
  font-weight: 400;
  color: black;
  font-size: 16px;
}

.labelledProressBarRoot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 4px;
  height: 72.5px;
  width: 399px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;
}
.labelledProressBarRoot:hover {
  background-color: #e8e8e8;
}

.labelledProgressbarLabelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.detailedDataPageRoot {
  width: 399px;
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 15px;
  cursor: pointer;
}
.detailedDataPageRoot:hover {
  background-color: #e8e8e8;
  border-radius: 10px;
}

.detailedDataPageContentWrapperGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 4 equal columns */
  gap: 10px;
  padding: 10px;
}

.detailedDataPageLabel {
  font-style: italic;
  color: #696969;
  font-size: 12px;
}

.detailedDataPageLabelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.detailedDataPageTitle {
  font-weight: 600;
  color: dimgray;
  text-align: center;
}
