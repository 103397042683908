 
 
  
  .evenRow {
    background-color: #f5f5f5;
  }
  .data_grid_text_clr {
    color: #808080;
  }
  
  .data_grid_text_clr:hover {
    color: black;
  }
 
  .MuiDataGrid-columnHeaderTitleContainerContent{
    display: flex  !important;
    align-items: center !important;
    justify-content: center !important;
  }