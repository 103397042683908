.donut_header {
  height: 70px;
  min-width: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donut_container {
  width: 93px;
  flex-shrink: 0;
}

.donut_container svg {
  overflow: visible;
}

.donut_Wrapper {
  display: flex;
  gap: 10px;
  margin-left: 80px;
}

.donut_chart {
  position: relative;
}
.centered_element {
  position: absolute;
  top: 28%; /* Optional: Set vertical position if needed */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  /* Other styles for the element */
}
.goal_Achieved {
  opacity: 0;
  transition: opacity 0.5s ease;
  white-space: nowrap;
}

.donut_chart:hover .goal_Achieved {
  opacity: 100;
}
