.MuiDataGrid-columnHeaderTitleContainerContent {
  justify-content: flex-start;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-columnSeparator,
.MuiDataGrid-filler,
.MuiDataGrid-scrollbarFiller {
  background-color: rgba(244, 244, 244, 1) !important; /* Change this to your desired color */
}

.MuiDataGrid-footerContainer,
.MuiDataGrid-columnHeadersInner {
  background-color: rgba(244, 244, 244, 1) !important; /* Change this to your desired color */
  width: 100%;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}
