.css-r63pc0-MuiInputBase-root-MuiInput-root::after {
  display: none !important;
}
.css-r63pc0-MuiInputBase-root-MuiInput-root::after {
  border: none !important;
  outline: none !important;
  display: none !important;
}
.css-r63pc0-MuiInputBase-root-MuiInput-root:hover::after {
  border: none !important;
  outline: none !important;
  display: none !important;
}

.css-r63pc0-MuiInputBase-root-MuiInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border: none !important;
  outline: none !important;
}

.css-r63pc0-MuiInputBase-root-MuiInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border: none !important;
  outline: none !important;
}

.css-r63pc0-MuiInputBase-root-MuiInput-root::before {
  border: none !important;
  outline: none !important;
}
