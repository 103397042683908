.chartContainer {
  width: 240px;
  height: 250px;
}

.chartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.progressBarWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.billingSummaryRoot {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  overflow: scroll;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.graphContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
}
