.header {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  outline: none;
  border: none;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: none !important;
  border: none !important;
  width: 100%;
  font-weight: 800 !important;
}

.MuiDataGrid-columnHeaderDraggableContainer {
  overflow: hidden;
  outline: none !important;
  border: none !important;
  font-weight: 800 !important;
}

.MuiDataGrid-columnHeader {
  overflow: hidden;
  outline: none !important;
  border: none !important;
}

.MuiDataGrid-columnHeadersInner {
  font-weight: 800 !important;
  font-size: 14px;
}

.evenRow {
  background-color: #f5f5f5;
}

.data_grid_text_clr {
  color: #808080;
}

.data_grid_text_clr:hover {
  color: black;
}
/* --iep-primary: #4F5F6B;
  --iep-primary-muted: #818d96;
  --palette-background-color: #E8E8E8; */

.MuiDataGrid-columnHeaderTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
